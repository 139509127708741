<template>
   <div>
      <load-screen-component :is-loading="isLoading"></load-screen-component>
      <page-header pageTitle="CLIENTS.DOCUMENT"/>
      <div v-if="!items?.validations && items?.data" class="card mw-100">
         <div class="row mw-100">
            <div class="col-xl-4 col-lg-4 col-md-5 col-sm-7 col-7">
               <div class="font-weight-bolder mt-2 ml-5 text-left">
                  {{ this.t('CLIENTS.CLIENT') }}
               </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-3 col-sm-3 col-3">
               <div class="font-weight-bolder mt-2 text-left" style="margin-left: 3rem">
                  {{ this.t('CLIENTS.DOCUMENTS.ARCHIVE') }}
               </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-5 col-5">
               <div class="font-weight-bolder mt-2 text-left ml-4">
                  {{ this.t('CLIENTS.DOCUMENTS.SIZE') }}
               </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 font-bold"
                 style="font-size: 1rem;"></div>
         </div>
         <div v-for="(iten, key) in items?.data" :key="iten?.id" :class="key !=0 ? 'border-top2': ''">
            <div class="row mw-100 space-between mt-2 mb-2">
               <div class="col-xl-4 col-lg-4 col-md-4 col-sm-7 col-7">
                  <div class="list-primary hide-text">
                     <div class="w-auto">
                       {{iten?.client?.name}}
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-4 col-md-4 col-sm-7 col-7">
                  <div class="list-primary hide-text">
                     <div class="w-auto">
                       {{iten?.name}}
                     </div>
                  </div>
               </div>
               <div class="col-xl-3 col-lg-3 col-md-4 col-sm-7 col-7">
                  <div class="list-primary hide-text">
                     <div class="w-auto">
                        {{formatSize(iten?.file_size)}}
                     </div>
                  </div>
               </div>
               <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                  <div class="text-right">
                     <a type="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false"
                        style="font-size: 20px" id="listDropdown">
                        <i class="icon-more_vert" title="Opções"></i>
                     </a>
                     <div class="dropdown-menu dropdown-menu-right dropdown-black">
                        <div class="dropdown-item pointer text-white"
                             data-toggle="modal" data-target="#customModalTwo" @click="setViewModal(iten)">
                           <i class="icon-info_outline mr-2 font-15"/>{{ t('ACTIONS.INFO') }}
                        </div>
                        <div @click="downloadDocument(iten)" class="dropdown-item pointer text-white">
                           <i class="icon-download mr-2 font-15"/>{{ t('CLIENTS.DOCUMENTS.DOWNLOAD') }}
                        </div>
                        <div class="dropdown-item pointer text-white" @click="documentDelete(iten.id)">
                           <i class="icon-trash-2 mr-2 font-15"/>{{ t('CLIENTS.DOCUMENTS.DELET') }}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="card" v-else>
         <div class="card-header"></div>
         <div class="card-body pt-0">
            <div class="text-center">
               <h5>{{ t('CLIENTS.DOCUMENTS.NOT_FOUND') }}</h5>
            </div>
         </div>
      </div>
      <modal :titleModal="modalTitle" :modalLarge="true">
         <template v-slot:title>{{ t(modalTitle) }}</template>
         <view-drive-document-component v-if="wichModal === '1'" :document-index="iten"></view-drive-document-component>
      </modal>
      <pagination-component v-if="items && items.data && items.data[0]" :items="items" :to="'/documentos'"
                            @changePage="index(filter, $event)"></pagination-component>
   </div>
</template>

<script>
import PageHeader from "@/components/layouts/pageHeader";
import LoadScreenComponent from "@/components/layouts/loadScreenComponent";
import {mapState} from "vuex";
import Drive from "../../services/Drive";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import Modal from "@/components/modal";
import viewDriveDocumentComponent from "./viewDriveDocumentComponent";
import PaginationComponent from "@/components/layouts/PaginationComponent";

export default {
   name: "indexDriveDocumentsComponent",
   components: {
      PageHeader,
      LoadScreenComponent,
      Modal,
      viewDriveDocumentComponent,
      PaginationComponent
   },
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },
   computed: {
      ...mapState({
         isLoading: 'isLoading'
      })
   },
   data () {
      return{
         items: null,
         modalTitle: '',
         iten: {},
          filter: null,
      }
   },
   mounted() {
      this.index();
   },
   methods:{
      index(filter = null, page = 1){
          console.log(filter)
         this.$store.commit('changeLoading', true);
         Drive.index(filter, page).then((resp) => {
            this.items = resp.data;
            this.$store.commit('changeLoading', false);
         }).catch(() =>  this.$store.commit('changeLoading', false));
      },
      formatSize(fileSize) {
         if (!fileSize) return '0.0';
         if (fileSize < 1000) return `${fileSize} Bytes`;
         if (fileSize > 1000 && fileSize < 1000000) return `${(fileSize / 1000).toFixed()} KB`;
         if (fileSize >= 1000000 && fileSize < 1000000000) return `${(fileSize / 1000000).toFixed(2)} MB`;
         if (fileSize >= 1000000000) return `${(fileSize / 1000000000).toFixed(2)} GB`;
      },
      async downloadDocument(doc) {
         this.$store.commit('changeLoading', true);
         await Drive.download(doc.id).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], {
               type: 'application/pdf'
            }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', doc.name);
            link.click();
            this.$store.commit('changeLoading', false);
         }).catch(() => this.$store.commit('changeLoading', false));
      },
      setViewModal(iten){
         this.$store.commit('changeLoading', true);
         this.modalTitle = 'CLIENTS.DOCUMENTS.VIEW'
         this.iten = iten;
         this.wichModal = '1';
         this.$store.commit('changeLoading', false);
      },
      documentDelete(id){
         this.$store.commit('changeLoading', true);
         Drive.fileDelete(id).then(() => {
            this.toast.success(this.t('CLIENTS.DOCUMENTS.DELETE_SUCCESSFULL'));
            this.index();
         }).catch(err => {
            this.errorMsg(err);
            this.$store.commit('changeLoading', false);
         }).finally(() =>  this.$store.commit('changeLoading', false));
      }
   }
}
</script>

<style scoped>

</style>